const timerClass = 'js-sale-timer';
const timerHClass = 'js-sale-timer-h';
const timerMClass = 'js-sale-timer-m';
const timerSClass = 'js-sale-timer-s';

const timers = document.getElementsByClassName(timerClass);
if (timers.length > 0) {
    Array.prototype.forEach.call(timers, function (item) {
        // Do stuff here
        let end = new Date(item.dataset.expire);
        let _second = 1000;
        let _minute = _second * 60;
        let _hour = _minute * 60;
        let _day = _hour * 24;
        let timer;

        function showRemaining() {
            let now = new Date();
            let distance = end - now;
            if (distance < 0) {
                clearInterval(timer);
                return;
            }
            // var days = Math.floor(distance / _day)
            // var hours = Math.floor((distance % _day) / _hour)
            let hours = Math.floor(distance / _hour);
            let minutes = Math.floor((distance % _hour) / _minute);
            let seconds = Math.floor((distance % _minute) / _second);

            // timer.getElementsByClassName('countdown').innerHTML = days + 'days ';
            item.getElementsByClassName(timerHClass)[0].innerHTML = String(
                hours
            ).padStart(2, '0');
            item.getElementsByClassName(timerMClass)[0].innerHTML = String(
                minutes
            ).padStart(2, '0');
            item.getElementsByClassName(timerSClass)[0].innerHTML = String(
                seconds
            ).padStart(2, '0');
            // console.log(item.getElementsByClassName(timerHClass))

            // console.log(hours.padStart(2,'0') + ' ' + minutes + ' ' + seconds)
        }

        timer = setInterval(showRemaining, 1000);
    });
}

// function refreshTimer(){
//     function countDown(){
//         setTimeout(function(now){
//             var dif = (td-now)/1000,
//                 ss = Math.floor(dif % 60).toString().padStart(2,"0"),
//                 ms = Math.floor(dif/60 % 60).toString().padStart(2,"0"),
//                 hs = Math.floor(dif/3600 % 24).toString().padStart(2,"0"),
//                 ds = Math.floor(dif/86400).toString().padStart(3,"0");
//                 // remainingTime.textContent = dif > 0 ? `${ds} Days ${hs}:${ms}:${ss}`
//                                                         // : "Sorry. You are already late..!";
//             active && countDown();
//             this.removeEventListener("change", kill); // possibly redundant
//         }, 1000, Date.now());
//     }
//     var td = new Date(this.value),
//         active = true,
//         kill = _ => active = false;
//     this.addEventListener("change", kill);
//     countDown();
// }
